<template>
  <base-page>
    <el-card style="margin: 5px 0px 5px 0px">
      <el-collapse accordion style="border: none">
        <el-collapse-item name="1" style="border: none">
          <template slot="title">
            <div slot="header" class="clearfix">
              <titlu-pagina Titlu="Facturi" @on_add_clicked="show_dialog()" :AdaugaVisible="true" />
            </div>
          </template>
          <div class="filtre">
            <el-form @submit.prevent.native="refresh_info()" label-position="top">
              <el-row :gutter="20">
                <el-col :md="4">
                  <el-form-item label="Data" size="mini" >
                    <el-date-picker size="mini" v-model="Filters.DataEmitere" type="date" value-format="yyyy-MM-dd"
                      format="dd.MM.yyyy" />
                  </el-form-item>
                </el-col>
                <el-col :md="4">
                  <el-form-item label="Nume client" size="mini" >
                    <el-input size="mini" v-model="Filters.NumeClient" />
                  </el-form-item>
                </el-col>
                <el-col :md="4">
                  <el-form-item label="Nr. Doc" size="mini" >
                    <el-input size="mini" v-model="Filters.Numar" />
                  </el-form-item>
                </el-col>
                <el-col :md="4">
                  <el-form-item label="CIF client" size="mini" >
                    <el-input size="mini" v-model="Filters.CifClient" />
                  </el-form-item>
                </el-col>
                <el-col :md="4">
                  <el-form-item label="Reg. Com. client" size="mini" >
                    <el-input size="mini" v-model="Filters.RegComClient" />
                  </el-form-item>
                </el-col>
                <!-- <el-col >
                            <el-form-item label='Status plata' >
                                <el-select class='full-width' v-model='Filters.StatusPlata' >
                                    <el-option label='Toate' value='-1'></el-option>
                                    <el-option label='platita' value='platita'></el-option>
                                    <el-option label='neplatita' value='neplatita'></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col >
                            <el-form-item label='Status' >
                                <el-select class='full-width' v-model='Filters.Status' >
                                    <el-option label='Toate' value='-1'></el-option>
                                    <el-option label='draft' value='draft'></el-option>
                                    <el-option label='emisa' value='emisa'></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col >
                            <el-form-item label='Anulata' >
                                <el-checkbox class='full-width' v-model='Filters.EsteAnulata' true-label='1' false-label='0'> DA </el-checkbox>
                            </el-form-item>
                                    </el-col>                         -->
                <el-col style="width: 100%">
                  <div class="footer-form">
                    <el-button size="mini" type="primary" native-type="submit" @click="refresh_info()">
                      Aplica
                    </el-button>
                    <el-button size="mini" type="danger" native-type="submit" @click="reset()">
                      Reseteaza
                    </el-button>

                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-card>

    <el-table :header-cell-style="{ background: '#393E46' }" :data="Results" class="tabelRandMic">
      <el-table-column prop="Id" label="#" width="44" />
      <el-table-column prop="NumeClient" label="Client" min-width="140"/>
      <el-table-column prop="NumeGestiune" label="Gestiune" />
      <!-- <el-table-column prop="CifClient" label="CIF"></el-table-column> -->
      <!-- <el-table-column prop="RegComClient" label="Reg. Com."></el-table-column> -->
      <el-table-column label="Serie / Numar">
        <template slot-scope="scope">
          {{scope.row.Serie}} {{scope.row.Numar}}

        </template>
      </el-table-column>
      <el-table-column label="Data">
        <template slot-scope="scope">
          {{ scope.row.DataFactura | Data() }}
        </template>
      </el-table-column>      
      <el-table-column label="Scadenta">
        <template slot-scope="scope">
          <span :style="(new Date(scope.row.Scadenta) < new Date() && scope.row.Incasare.Status != 'Da') ? 'color: red;':''">
            {{ scope.row.Scadenta | Data() }}
          </span>
        </template>
      </el-table-column>
      <!-- <el-table-column style="text-align: right;" prop="ValoareFaraTVA" label="Valoare fara TVA Ron" /> -->
      <el-table-column align="right"  label="Valoare cu TVA RON">
        <template slot-scope="scope">
          {{ (scope.row.ValoareCuTVACuDisc) | format_money }}
        </template>
      </el-table-column>  
      <el-table-column label="Incasata" style="text-align: center;" width="100px">
        <template slot-scope="scope">
          <span v-if="scope.row.Incasare.Status == 'Da'">Da</span>
          <el-tooltip :content="'Ramas: '+scope.row.Incasare.DeIncasat" v-else-if="scope.row.Incasare.Status == 'Partial'">
            <span>Partial</span>
          </el-tooltip>
          <span v-else>Nu</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="Actiuni" width="200px">
        <template slot-scope="scope">
          <el-tooltip content="Modificare">
            <!-- <el-tooltip v-if="$has_right('modificare-factura')" content="Modificare"> -->
            <el-button :disabled="scope.row.LunaBlocata" type="primary" size="mini" icon="el-icon-edit" circle @click="show_dialog(scope.row.Id)"  class="butonReg" />
          </el-tooltip>          
          <el-tooltip content="Storneaza">
            <!-- <el-tooltip v-if="$has_right('modificare-factura')" content="Modificare"> -->
            <el-button v-if="scope.row.ValoareCuTVACuDisc > 0" type="danger" size="mini" icon="el-icon-sell" circle @click="storneaza(scope.row.Id)"  class="butonReg" />
          </el-tooltip>
          <el-tooltip content="Imprimare">
            <el-button type="info" size="mini" icon="el-icon-printer" circle @click="print(scope.row.Id)" class="butonReg" />
          </el-tooltip>
          <el-tooltip content="Sterge" >
            <el-button :disabled="scope.row.LunaBlocata" type="danger" size="mini" icon="el-icon-delete" circle @click="delete_item(scope.row)" class="butonReg" />
          </el-tooltip> 
          <el-tooltip content="Genereaza eFactura">
            <el-button type="warning" size="mini" icon="el-icon-download" circle @click="efactura(scope.row)" class="butonReg" />
          </el-tooltip>
          <el-tooltip content="Descarca PDF">
            <el-button type="success" size="mini" icon="el-icon-download" circle @click="downloadPdf(scope.row)"  class="butonReg" />
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="refresh_info" @current-change="refresh_info" :page-size.sync="PaginationInfo.PerPage"
      :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
    <Facturi-dialog ref="dlg" @save="refresh_info()" />
  </base-page>

</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Facturi_dialog from "@/pages/facturi/Facturi_dialog.vue";
import TitluPagina from "@/widgets/TitluPagina";
import { jsPDF } from "jspdf";
import html2pdf from "html2pdf.js";

export default {
  name: "facturi",
  extends: BasePage,
  components: {
    "base-page": BasePage,
    "Facturi-dialog": Facturi_dialog,
    "titlu-pagina": TitluPagina,
  },
  data() {
    return {
      Results: [],
      base_url: "",
      Info: {
        masini: [],
      },
      Filters: {
        DataEmitere: "",
        NumeClient: "",
        CifClient: "",
        RegComClient: "",
        Numar: "",
      },
      OrderBy: {},
      PaginationInfo: {
        Page: 1,
        PerPage: 50,
        RowCount: 0,
        PageSizes: [10, 25, 50, 100, 200],
      },
    };
  },
  methods: {
    async get_info() {
      // if (!this.$has_right('vizualizare-facturi')){
      //       this.$router.push('/no-rights')
      //       return
      // }
      var response = await this.post("facturi/get_info");
      this.Info.masini = response.masini;
      //if( this.Filters.IdMasina.length > 0 ) this.Filters.IdMasina = this.Info.masini[0].Id;
      this.refresh_info();
    },

    async refresh_info() {
      var response = await this.post("facturi/index", {
        Filters: this.Filters,
        OrderBy: this.OrderBy,
        PaginationInfo: this.PaginationInfo,
      });
      this.Results = response.Results;
      this.PaginationInfo = response.PaginationInfo;
      //
      this.select_menu_item("facturi");
    },
    reset() {
      this.Filters = {
        DataEmitere: "",
        NumeClient: "",
        CifClient: "",
        RegComClient: "",
        Numar: "",
      };
      this.refresh_info();
    },

    async delete_item(item) {
      this.$confirm(`Sunteti sigur ?`, "Warning", {
        type: "warning",
      })
        .then(async () => {
          await this.post("facturi/delete_item", { id: item.Id });
          this.refresh_info();
          this.$message({
            type: "success",
            message: "Stergere efectuata cu succes",
          });
        })
        .catch(() => {
          this.$message({ type: "info", message: "Stergere anulata" });
        });
    },

    show_dialog(id) {
      this.$refs.dlg.show_me(id);
    },    
    
    storneaza(id) {
      this.$refs.dlg.show_stornare(id);
    },
    async print(id) {

      let res = await this.post('facturi/print_facturi', {IdFactura: id});
      // console.log(res)
      // return

      let doc = window.open("", "_blank");
      doc.document.write(res.html);
    },    
    
    async efactura(factura) {

      let res = await this.post('efactura/genereaza_efactura', {IdFactura: factura.id});


      let blob = new Blob([res], { type: 'application/xml' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = factura.Serie + ' ' + factura.Numar + '.xml'
      link.click()

    },

    async downloadPdf(fact){

      let res = await this.post('facturi/print_facturi', {IdFactura: fact.Id});
      //const toDownloadDocument = new jsPDF()
      const blankWindow = window.open('', '_blank');

      html2pdf(res.html, {
				margin: 0,
  			filename: 'Factura_' + fact.Serie + '_' + fact.Numar,
        html2canvas: { scale: 4 },
			}).then(() => {
        blankWindow.close();
      });

      // toDownloadDocument.html(res.html, {
      //   callback: () => {
      //     toDownloadDocument.save('Factura_' + fact.Serie + '_' + fact.Numar)
      //     blankWindow.close();
      //   },
      //   x: 15,
      //   y: 15,
      //   width: 180,
      //   windowWidth: 1200,
      // })
    }

  },
  mounted() {
    this.base_url = settings.BASE_URL;
    this.get_info();
  },

};
</script>

<style lang="less" scoped>
.top50 {
  margin-top: 20px;
}
@media only screen and (min-width: 768px) {
  .filtre {
    display: flex;
    grid-template-columns: none;
  }

  .el-table tr {
    word-break: break-word;
    // font-size: 12px !important;
  }

  .footer-form {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
}

.filtre {
  width: 100% !important;
  display: grid;
  grid-template-columns: repeat(2);

  .el-col {
    width: 31%;
    margin-right: 2%;
  }

  .el-date-editor {
    width: 100%;
  }

  // grid-auto-rows: 100px;
  .el-input-number {
    width: 100% !important;
  }
}
</style>
